// src/redux/authSlice.ts
import { cookieStorageKeys } from '@/constants/storageKeys';
import { getClientCookie } from '@/utils/client-cookie';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    AccessToken: string | null;
}

const initialState: AuthState = {
    AccessToken: getClientCookie(cookieStorageKeys.END_USER_AUTH_KEY) || null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.AccessToken = action.payload;
        },
        logout: (state) => {
            state.AccessToken = null;
        },
    },
});

export const { setToken, logout } = authSlice.actions;
export default authSlice.reducer;
