import(/* webpackMode: "eager", webpackExports: ["HeroUIProvider"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/@heroui/system/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/@heroui/toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"vietnamese\"],\"preload\":true,\"weight\":[\"200\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/src/app/StoreProvider.tsx");
