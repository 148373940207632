import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    RetryOptions,
} from '@reduxjs/toolkit/query/react';
import { logout, setToken } from './auth/authSlice';
import { RootState } from './store';

// Define a base query with axios and RTK Query
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL, // Set your API base URL here
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.AccessToken;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    credentials: 'include', // Automatically sends cookies with requests
});

// Custom base query with refresh token logic
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {} & RetryOptions,
    FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // Try to refresh the token
        const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions);

        if (refreshResult.data) {
            // Store the new token
            //@ts-ignore
            api.dispatch(setToken(refreshResult.data.AccessToken));

            // Retry the original request with the new token
            result = await baseQuery(args, api, extraOptions);
        } else {
            // If refresh fails, log out user
            api.dispatch(logout());
        }
    }

    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({}),
});
