import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface QuotationState {
    isFetchingPrice: boolean;
}

const initialState: QuotationState = {
    isFetchingPrice: false,
};

const quotationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
        setIsFetchingPrice: (state, action: PayloadAction<boolean>) => {
            state.isFetchingPrice = action.payload;
        },
    },
});

export const { setIsFetchingPrice } = quotationSlice.actions;
export default quotationSlice.reducer;
